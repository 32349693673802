import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FormService from 'services/FormService';

export const initialState = {
	objectListData: {
		objects: [],
		isLoading: false,
		isLoaded: false,
	},
	selectedObjectData: {
		object: null,
		isLoading: false,
		isLoaded: false,
	},
	isToggleLoading: false
}


export const createObject = createAsyncThunk('form/createObject', async (data, { rejectWithValue }) => {
	try {
		const response = await FormService.createObject(data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObjects = createAsyncThunk('form/getObjects', async (params, { rejectWithValue }) => {
	try {
		const response = await FormService.getObjects(params);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const getObject = createAsyncThunk('form/getObject', async (id, { rejectWithValue }) => {
	try {
		const response = await FormService.getObject(id);
		return response
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateObject = createAsyncThunk('form/updateObject', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.updateObject(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const deleteObject = createAsyncThunk('form/deleteObject', async (id, { rejectWithValue }) => {
	try {
		const response = await FormService.deleteObject(id);
		return id
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const toggleFormField = createAsyncThunk('form/toggleFormField', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.toggleFormField(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const sortFields = createAsyncThunk('form/sortFields', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.sortFields(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const addField = createAsyncThunk('form/addField', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.addField(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const duplicateField = createAsyncThunk('form/duplicateField', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.duplicateField(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const submitObject = createAsyncThunk('form/submitObject', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.submitObject(id, data);
		if (response.object) {
			return response.object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const createNestedForm = createAsyncThunk('form/createNestedForm', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.createNestedForm(id, data);
		if (response.object) {
			return response.object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const deleteNestedForm = createAsyncThunk('form/deleteNestedForm', async ({ id, formId }, { rejectWithValue }) => {
	try {
		const response = await FormService.deleteNestedForm(id, formId);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateNestedForm = createAsyncThunk('form/updateNestedForm', async ({ id, formId, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.updateNestedForm(id, formId, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const assignUsers = createAsyncThunk('form/assignUsers', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.assignUsers(id, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const createSeparator = createAsyncThunk('form/createSeparator', async ({ id, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.createSeparator(id, data);
		if (response.object) {
			return response.object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const deleteSeparator = createAsyncThunk('form/deleteSeparator', async ({ id, separatorId }, { rejectWithValue }) => {
	try {
		const response = await FormService.deleteSeparator(id, separatorId);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})
export const updateSeparator = createAsyncThunk('form/updateSeparator', async ({ id, separatorId, data }, { rejectWithValue }) => {
	try {
		const response = await FormService.updateSeparator(id, separatorId, data);
		if (response.object) {
			const object = response.object;
			return object;
		} else {
			return rejectWithValue(response.message);
		}
	} catch (err) {
		return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
	}
})

export const objectSlice = createSlice({
	name: 'tableSchema',
	initialState,
	reducers: {
		setSelectedObjectData: (state, action) => {
			const { object } = action?.payload;
			state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getObjects.fulfilled, (state, action) => {
				const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result
				state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
			})
			.addCase(getObjects.pending, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
			})
			.addCase(getObjects.rejected, (state) => {
				state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.fulfilled, (state, action) => {
				const { object } = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
			})
			.addCase(getObject.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: false, isLoading: true }
			})
			.addCase(getObject.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isLoaded: true, isLoading: false }
			})
			.addCase(deleteObject.fulfilled, (state, action) => {
				const objectId = action?.payload;
				const objects = state.objectListData.objects.filter(object => object?._id !== objectId);
				state.objectListData = { ...state.objectListData, objects }
			})

			.addCase(updateObject.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [...state.objectListData.objects];
				objects.splice(index, 1, object)
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(toggleFormField.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
				state.isToggleLoading = false;
			})
			.addCase(toggleFormField.pending, (state) => {
				state.isToggleLoading = true;
			})
			.addCase(toggleFormField.rejected, (state) => {
				state.isToggleLoading = false;
			})
			.addCase(sortFields.fulfilled, (state, action) => {
				const object = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(sortFields.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(sortFields.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			.addCase(addField.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(duplicateField.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(deleteNestedForm.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})

			.addCase(createNestedForm.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateNestedForm.fulfilled, (state, action) => {
				const object = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(updateNestedForm.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(updateNestedForm.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			.addCase(assignUsers.fulfilled, (state, action) => {
				const object = action?.payload;
				const index = state.objectListData.objects.findIndex(item => item?._id == object?._id)
				const objects = [ ...state.objectListData.objects ];
				objects.splice(index, 1, object)
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(deleteSeparator.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})

			.addCase(createSeparator.fulfilled, (state, action) => {
				const object = action?.payload;
				const objects = [...state.objectListData.objects.filter(item => item?._id !== object?._id), object];
				state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
				state.objectListData = { ...state.objectListData, objects }
			})
			.addCase(updateSeparator.fulfilled, (state, action) => {
				const object = action?.payload;
				state.selectedObjectData = { ...state.selectedObjectData, object, isEditLoading: false }
			})
			.addCase(updateSeparator.pending, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: true }
			})
			.addCase(updateSeparator.rejected, (state) => {
				state.selectedObjectData = { ...state.selectedObjectData, isEditLoading: false }
			})
			
	},
})

export const {
	setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer